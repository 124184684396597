import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import logo from '../../images/logopearson.svg';
import { signOut } from '../../utils/authentication';

const Banner = (props) => {
  const onSignOut = () => {
    signOut(props.instance);
  };
  return (
    <AppBar
      position="fixed"
      sx={{
        color: '#000',
        backgroundColor: '#151515',
        padding: '2px 0',
        boxShadow: 'none',
      }}>
      <Toolbar variant="dense" sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <img src={logo} alt="pearson logo" />

        <Button
          sx={{
            backgroundColor: '#9E007E',
            color: '#fff',
            borderRadius: '20px',
            padding: '5px 20px',
            '&:hover': {
              backgroundColor: '#9E007E',
              color: '#fff',
            },
          }}
          onClick={onSignOut}>
          Sign out
        </Button>
      </Toolbar>
    </AppBar>
  );
};

Banner.propTypes = {
  instance: PropTypes.object,
};

export default Banner;
